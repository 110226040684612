import { Provider } from '../provider';
class Tieba extends Provider {
    create(options) {
        if (!options.desc) {
            options.desc = options.summary;
        }
        if (!options.comment) {
            options.comment = options.summary;
        }
        return super.create(options);
    }
    getName() {
        return 'tieba';
    }
    getUrlTemplate() {
        return 'http://tieba.baidu.com/f/commit/share/openShareApi?url={url}&title={title}&desc={desc}&comment={comment}';
    }
}
export default Tieba;
